"use strict";
import "./ts/delayed-scroll-restoration-polyfill.js";
import "./ts/blurOnPageChange.js";
import {firebase, enableFirestorePersistence} from "../_snowpack/link/packages/frontend/common/firebase.js";
import * as Log from "../_snowpack/link/packages/frontend/common/logging.js";
import * as User from "./ts/elm/ports/user.js";
import * as Auth from "./ts/elm/ports/auth.js";
import * as SearchIndices from "./ts/elm/ports/search-indices.js";
import * as SearchWeightIndex from "./ts/elm/ports/search-weight-indices.js";
import * as SlateEditor from "../_snowpack/link/packages/editor-element/src/customElement.js";
import * as WitfulSearchBar from "../_snowpack/link/packages/searchbar/src/customElement.js";
import * as rollbar from "../_snowpack/link/packages/frontend/common/rollbar.js";
import * as app from "./ts/app.js";
import * as Analytics from "./ts/analytics.js";
import ClipboardJS from "../_snowpack/pkg/clipboard.js";
import env from "../_snowpack/link/packages/frontend/common/env.js";
import assets from "../static/assets.js";
import * as attachments from "./ts/elm/ports/attachments.js";
enableFirestorePersistence();
console.log("running", {
  appVersion: env.APP_VERSION,
  commit: env.COMMIT_HASH
});
new ClipboardJS(".copy-to-clipboard");
const elmApp = app.init();
const toEditorPlugins = (entityId, eventData) => ({
  images: {
    uploadPendingImageUrl: assets.loadingSpinner,
    uploadImage: attachments.uploadFile(elmApp, entityId, true)
  },
  mentions: {
    sendSearchTerm: elmApp.ports.mentionSearchFromEditor.send,
    subscribeSearchResult: elmApp.ports.mentionResultToEditor.subscribe
  },
  actionItems: {eventData}
});
SlateEditor.init(Log, toEditorPlugins);
WitfulSearchBar.init(elmApp.ports.setSearchbarQuery_.subscribe);
let firstLoad = true;
firebase.auth().onAuthStateChanged((user) => {
  Log.debug("authStateChanged", user);
  if (user) {
    Analytics.setUserProperties(user.uid, user.email);
    rollbar.setPerson(user.uid, user.email);
    void SearchWeightIndex.sendToElm(user, elmApp);
    void SearchIndices.sendToElm(user, elmApp);
  }
  if (!firstLoad && !user) {
    Auth.handleSignoutRedirect();
  } else {
    void User.sendToElm(user, elmApp);
    firstLoad = false;
  }
});
