import {useFocused, useSelected} from "../../../../../pkg/slate-react.js";
import React from "../../../../../pkg/react.js";
import {css} from "../../../../../pkg/@emotion/css.js";
const mentionChipStyling = (palette) => {
  const selected = useSelected();
  const focused = useFocused();
  return css`
    text-decoration: none;
    font-size: 14px;
    padding: 0px 4px 2px;
    border-radius: 4px;
    color: ${palette?.blue70};
    background-color: ${palette?.blue0 || "lightblue"};
    box-shadow: ${selected && focused ? "0 0 0 3px " + (palette?.gray70 || "lightblue") : "none"};
  `;
};
export const Element = (palette) => ({
  attributes,
  children,
  element
}) => {
  const element_ = element;
  const chipText = (data) => {
    if (data.type === "event-chip") {
      return data.eventTitle.trim().length === 0 ? "#(Untitled)" : "#" + data.eventTitle;
    }
    return data.personName.trim().length === 0 ? "#(No name)" : "#" + data.personName;
  };
  switch (element_.type) {
    case "p":
      return /* @__PURE__ */ React.createElement("span", {
        ...attributes
      }, children);
    case "search-bar-chip":
      return /* @__PURE__ */ React.createElement("span", {
        ...attributes,
        className: mentionChipStyling(palette)
      }, /* @__PURE__ */ React.createElement("span", null, chipText(element_.data)), children);
    default:
      return /* @__PURE__ */ React.createElement("span", {
        ...attributes
      }, children);
  }
};
